// extracted by mini-css-extract-plugin
export var lbContainerOuter = "H_vN";
export var lbContainerInner = "H_vP";
export var movingForwards = "H_vQ";
export var movingForwardsOther = "H_vR";
export var movingBackwards = "H_vS";
export var movingBackwardsOther = "H_vT";
export var lbImage = "H_vV";
export var lbOtherImage = "H_vW";
export var prevButton = "H_vX";
export var nextButton = "H_vY";
export var closing = "H_vZ";
export var appear = "H_v0";