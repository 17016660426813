// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "t_sw d_bD";
export var datasheetRowWrapper = "t_lt d_lt d_bK";
export var datasheetLeftWrapper = "t_sx d_bz d_bP";
export var datasheetWrapperFull = "t_sy d_cD";
export var datasheetWrapperFullLeft = "t_pf d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "t_mv d_lv";
export var contentWrapperCenter = "t_sz d_lz";
export var datasheetLeftWrapperCenter = "t_sB d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "t_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "t_sC d_lw";
export var datasheetFooter = "t_ly d_ly";
export var alignLeft = "t_qh d_dv";
export var alignCenter = "t_bP d_dw";
export var alignRight = "t_qj d_dx";
export var datasheetInnerWrapperNewLeft = "t_sD d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "t_sF d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "t_sG d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "t_sH d_bK";
export var datasheetSubtitle = "t_sJ d_cx";
export var tableRow = "t_sK";
export var cell = "t_sL";
export var tableRowWrapper = "t_sM d_w d_dv";
export var tableHeadingCell = "t_sN";
export var tableHeading = "t_sP";
export var tableRowStriped = "t_sQ";
export var tableRowHeading = "t_sR";
export var dataTable = "t_sS d_w";
export var imageWrapper = "t_sT d_fg d_Z";
export var imageWrapperFull = "t_sV d_H d_w d_bf d_Z";
export var imageWrapperIcon = "t_sW";
export var titleMargin = "t_sX d_cs";
export var datasheetInnerInnerWrapper = "t_sY d_w";
export var hasLabels = "t_sZ";
export var label = "t_s0";
export var SmallSmall = "t_rf q_rf q_qr q_qP";
export var SmallNormal = "t_rg q_rg q_qr q_qQ";
export var SmallLarge = "t_rh q_rh q_qr q_qM";